<template>
    <AppLayout>
        <RequireLogin>
        <Tutorial next="/account">
            <p class="text-subtitle-1 font-weight-light text-center">The <b>dashboard</b> is where you can see what's new and see an overview of the activity in the account. You can return to this page at any time by clicking the LoginShield logo on the top-left corner of the window, or by selecting an account from the menu on the top-right corner of the window.</p>
        </Tutorial>
        <v-row justify="center" class="py-5">
            <h1 class="text-h6 font-weight-light">{{ displayName }}</h1>
        </v-row>
        <!-- NOTE: see same card in DashboardFree and DashboardEnterprise -->
        <!-- <v-row justify="center" class="py-5">
            <v-col cols="12" sm="10" md="8" lg="6" xl="4" class="pa-0">
                <v-card elevation="6" class="px-5 pb-5">
                    <v-card-text class="text-h6 text-center">Tutorial</v-card-text>
                    <v-divider class="mx-5 mb-5"></v-divider>
                    <v-row justify="center" class="py-5 px-5">
                        <p class="text-body-1 font-weight-light text-center">
                            Enable the tutorial to get helpful information for any page.
                            Look for the <b>Tutorial Mode</b> switch in the blue help bar
                            at the bottom of each page.
                        </p>
                    </v-row>
                </v-card>
            </v-col>
        </v-row> -->
        <v-row justify="center" class="py-5">
            <v-col cols="12" sm="10" md="8" lg="6" xl="4" class="pa-0">
                <p><router-link :to="{ path: '/account', query: { acct: $route.query.acct } }">Account settings</router-link></p>
            </v-col>
        </v-row>
        <!-- NOTE: see same card in DashboardFree -->
         <v-row justify="center" class="py-5" v-if="isPermitEdit">
            <v-col cols="12" sm="10" md="8" lg="6" xl="4" class="pa-0">
                <v-card elevation="6" class="px-10 pb-5">
                    <v-card-text class="text-h6 text-center">Create Enterprise Account</v-card-text>
                    <v-divider class="mx-5 mb-5"></v-divider>
                    <v-row justify="center" class="py-5">
                        <p class="text-body-1 font-weight-light text-center">
                            <b>LoginShield Enterprise</b> enables your users to login to your website with LoginShield. Get started for free to check out what we can do for your business!
                        </p>
                    </v-row>
                    <v-row justify="center">
                        <!--
                        <v-btn elevation="6" class="blue white--text" to="/subscribe/enterprise">
                            <span>Create</span>
                        </v-btn>
                        -->
                        <v-btn elevation="6" class="blue white--text" href="/contact/?topic=create-enterprise-account">
                            <span>Contact us</span>
                        </v-btn>
                    </v-row>
                </v-card>
            </v-col>
        </v-row>
        </RequireLogin>
    </AppLayout>
</template>
<script>
import { mapState, mapGetters } from 'vuex';
import RequireLogin from '@/components/RequireLogin.vue';
import Tutorial from '@/components/Tutorial.vue';
import AppLayout from '@/components/AppLayout.vue';

export default {
    components: {
        RequireLogin,
        Tutorial,
        AppLayout,
    },
    computed: {
        ...mapState({
            session: (state) => state.session,
        }),
        ...mapGetters({
            currentAccount: 'account', // currently selected account object
        }),
        displayName() {
            return this.currentAccount ? this.currentAccount.name : 'Dashboard';
        },
        isPermitEdit() {
            if (this.currentAccount) {
                const permit = this.currentAccount.permit || [];
                return permit.includes('edit');
            }
            return false;
        },
    },
};
</script>
